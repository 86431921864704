/**
 * Created by lilit on 2019-03-08.
 */

import React, { FC } from 'react';
import { Formik, Form } from 'formik';
import {
  Row,
  Column,
  Card,
  CardActions,
  CardAction,
  CardBody,
} from '@nimles/react-web-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { InputField, RadioField } from '@nimles/react-web-forms';
import { ProductGroupWithProductProperties } from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrder } from '@nimles/react-redux';
import { OrderModel, PaymentOptionModel } from '@nimles/models';
import { RootState } from '../../../redux/types';
import { CardArea } from '../FormCard';
import { FieldSet } from '../../fields/FieldSet';
import { useState } from 'react';

interface Props {
  onSubmitted?: (values: any) => void;
  productGroups?: ProductGroupWithProductProperties[];
  categoryId?: string;
  productGroupId?: string;
  order: OrderModel;
  isOpen: boolean;
  onNext: () => void;
  onSelect: () => void;
}

export const DeliveryForm: FC<Props> = ({
  order,
  isOpen,
  onNext,
  onSelect,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deliveryOptions = useSelector<RootState, PaymentOptionModel[]>(
    ({ publicDeliveryOptions }) => publicDeliveryOptions.values
  );

  const handleSubmit = async (
    values,
    { setSubmitting, setErrors, setStatus }
  ) => {
    try {
      await dispatch(
        updateOrder({
          ...order,
          head: {
            ...order.head,
            deliveryOptionId: values.head.deliveryOptionId,
            buyer: { ...order.head.buyer, ...values.head.buyer },
          },
        })
      );
      onNext();
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      console.error(error);
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  const values: OrderModel = {
    head: {
      buyer: {
        deliveryAddress: {
          firstName:
            order.head.buyer.deliveryAddress?.firstName ||
            order.head.buyer?.firstName ||
            '',
          lastName:
            order.head.buyer.deliveryAddress?.lastName ||
            order.head.buyer?.lastName ||
            '',
          street:
            order.head.buyer.deliveryAddress?.street ||
            order.head.buyer.billingAddress?.street ||
            '',
          postalCode:
            order.head.buyer.deliveryAddress?.postalCode ||
            order.head.buyer.billingAddress?.postalCode ||
            '',
          city:
            order.head.buyer.deliveryAddress?.city ||
            order.head.buyer.billingAddress?.city ||
            '',
        },
      },
      deliveryOptionId: order.head.deliveryOptionId,
    },
  };

  return isOpen ? (
    <>
      Leverans
      <Formik
        initialValues={values}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        enableReinitialize
        render={({ handleSubmit, isSubmitting }) => {
          return (
            <Form
              onSubmit={handleSubmit}
              name="simpleForm"
              className="quote-request-form"
            >
              <Card>
                <CardArea>
                  <FieldSet>
                    <Row>
                      <Column flex>
                        <InputField
                          required
                          name="head.buyer.deliveryAddress.firstName"
                          label={t('prop.firstName')}
                          placeholder={t('prop.firstName')}
                        />
                      </Column>
                    </Row>
                    <Row>
                      <Column flex>
                        <InputField
                          required
                          name="head.buyer.deliveryAddress.lastName"
                          label={t('prop.lastName')}
                          placeholder={t('prop.lastName')}
                        />
                      </Column>
                    </Row>
                    <Row>
                      <Column flex>
                        <InputField
                          required
                          name="head.buyer.deliveryAddress.street"
                          label={t('prop.address')}
                          placeholder={t('prop.address')}
                        />
                      </Column>
                    </Row>
                    <Row>
                      <Column flex>
                        <InputField
                          required
                          name="head.buyer.deliveryAddress.postalCode"
                          label={t('prop.postalCode')}
                          placeholder={t('prop.postalCode')}
                        />
                      </Column>
                      <Column flex>
                        <InputField
                          required
                          name="head.buyer.deliveryAddress.city"
                          label={t('prop.city')}
                          placeholder={t('prop.city')}
                        />
                      </Column>
                    </Row>
                    <RadioField
                      options={deliveryOptions.map((deliveryOption) => ({
                        name: deliveryOption.name,
                        value: deliveryOption.id,
                      }))}
                      name="head.deliveryOptionId"
                    />
                  </FieldSet>
                </CardArea>
                <CardActions>
                  <CardAction
                    primary
                    type="submit"
                    raised
                    disabled={isSubmitting}
                  >
                    {t(isSubmitting ? 'action.next' : 'action.next')}
                  </CardAction>
                </CardActions>
              </Card>
            </Form>
          );
        }}
      />
    </>
  ) : order.head.buyer.deliveryAddress ? (
    <>
      Leverans{' '}
      <Card>
        <CardArea>
          <span>
            {values.head.buyer.deliveryAddress.firstName}
            {values.head.buyer.deliveryAddress.lastName}
          </span>
          <span>
            {values.head.buyer.deliveryAddress.street},
            {values.head.buyer.deliveryAddress.postalCode},
            {values.head.buyer.deliveryAddress.city}
          </span>
          <CardActions>
            <CardAction primary onClick={onSelect}>
              {t('update')}
            </CardAction>
          </CardActions>
        </CardArea>
      </Card>
    </>
  ) : null;
};
