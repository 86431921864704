import { OrderModel, OrganizationModel, UserModel } from '@nimles/models';
import { createOrder, loadOrders, updateOrder } from '@nimles/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/types';
import { useCurrency } from './currencyHook';

let orderPromise = Promise.resolve<OrderModel>(null);

export const useOrder = () => {
  const dispatch = useDispatch();
  const user = useSelector<RootState, UserModel>(
    ({ currentUser }) => currentUser.user
  );
  const orgnization = useSelector<RootState, OrganizationModel>(
    ({ organizations }) => organizations.selected
  );
  const { currency } = useCurrency();

  return {
    createOrUpdateOrder: (cartId: string) =>
      createOrUpdateOrder(dispatch, user, orgnization, currency, cartId),
  };
};

const createOrUpdateOrder = async (
  dispatch: any,
  user: UserModel,
  organization: OrganizationModel,
  currency: string,
  cartId: string
) => {
  orderPromise = orderPromise.then(async (order) => {
    try {
      if (
        !order ||
        (order.head.orderStatus !== 'New' &&
          order.head.orderStatus !== 'Reserved')
      ) {
        const orders = await dispatch(loadOrders());
        order = orders?.find(
          ({ head }) =>
            head.cartId === cartId &&
            head.buyer?.userId === user?.id &&
            (order.head.orderStatus === 'New' ||
              order.head.orderStatus === 'Reserved')
        );
      }
      if (order) {
        order = await dispatch(
          updateOrder({
            ...order,
            head: {
              ...order.head,
              currency,
            },
          })
        );
      } else {
        order = await dispatch(
          createOrder({
            head: {
              cartId,
              currency,
              buyer: {
                customerType: organization ? 'Organization' : 'Private',
                organizationName: organization?.name,
                identityNumber: organization?.corporateIdentity,
                organizationId: organization?.id,
                phoneNumber: organization?.phoneNumber ?? user?.phoneNumber,
                userId: user.id,
                email: user.username,
                firstName: user.firstName,
                lastName: user.lastName,
                billingAddress:
                  organization?.billingAddress ??
                  organization?.address ??
                  user.address,
                deliveryAddress:
                  organization?.deliveryAddress ??
                  organization?.address ??
                  user.address,
              },
            },
          })
        );
      }
    } catch (error) {
      console.error(error);
    }

    return order;
  });

  return await orderPromise;
};
